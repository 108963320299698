<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <section class="sabc_tier_sports_sec" v-else-if="is_data_ready">
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                    <div class="sabc_tier_news_tabs">
                        <h1>{{$route.params.slug.toUpperCase()}}</h1>
                    </div>
                    <div class="sabc_tier_news_sec" v-for="article in sportsTagNews.data">
                        <div class="sabc_tier_news_row">
                            <div class="sabc_tier_news_pic">
                                <router-link :to="{name: 'news_detail', params: {sport:article.sport.slug , news: 'news' ,slug: article.slug}}">
                                    <img :src="$resizeImage(article.image.image_path,368,232)" :alt="article.image_caption" height="232" width="368"/>
                                </router-link>
                            </div>
                            <div class="sabc_tier_news_desc">
                                <h2>
                                    <router-link :to="{name: 'news_detail', params: {sport:article.sport.slug , news: 'news' ,slug: article.slug}}">
                                        {{article.headline}}
                                    </router-link>
                                </h2>
                                <p v-html="article.stand_first_simple">
                                </p>
                                <label>
                                    {{article.start_date | formatDate2}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <nav v-if="Math.ceil(sportsTagNews.total / sportsTagNews.per_page) > 1">
                        <ul class="pagination">
                            <li :class="{'disabled': (pageNo == 1)}" aria-label="« Previous" class="page-item">
                                <router-link :to="{ name: 'sport_tag_news', params: { sport: $route.params.sport, slug:$route.params.slug, page: ( (pageNo == 1)? pageNo: pageNo-1 )}}">
                                    <!--Made page-value at start-->
                                    <span class="page-link">«</span>
                                </router-link>
                            </li>

                            <li :class="{ 'active' : (pageNo == $route.params.page) }"
                                :key="pageNo" aria-current="page" class="page-item"
                                v-for="pageNo in Math.ceil(sportsTagNews.total / sportsTagNews.per_page)">
                                <router-link
                                        :to="{ name: 'sport_tag_news', params: { sport: $route.params.sport, slug: $route.params.slug, page: (pageNo)}}"
                                        class="page-link">
                                    {{pageNo}}
                                </router-link>
                            </li>

                            <li :class="{'disabled': (pageNo == Math.ceil(sportsTagNews.total / sportsTagNews.per_page))}"
                                class="page-item">
                                <router-link
                                        :to="{ name: 'sport_tag_news', params: { sport: $route.params.sport, slug: $route.params.slug, page: ( (pageNo != Math.ceil(sportsTagNews.total / sportsTagNews.per_page))? (Number(pageNo) + 1) : pageNo ) }}">
                                    <!-- Made page-value to end -->
                                    <span aria-label="Next »" class="page-link">»</span>
                                </router-link>
                            </li>

                        </ul>
                    </nav>
                </div>
               <SideBar :editorsPick="editorsPick"></SideBar>
            </div>
        </div>
    </section>
</template>

<script>
    import EditorPicks from '@/components/common/editor-pick';
    import SideBar from '@/components/common/sidebar/sidebar';

    export default {
        name: "sport-tags-news",
        metaInfo() {
            if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;

                var replacements = {
                    "%SPORT%": this.$route.params.sport,
                    "%TAG%": this.$route.params.slug,
                    "%PAGE%": this.pageNo,
                };
                let title = this.$getSafe(() => this.$replacePlaceHolders(ss['tag_meta_title'],replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss['tag_meta_description'],replacements));
                let keywords = this.$getSafe(() => this.$replacePlaceHolders(ss['tag_meta_keywords'],replacements))

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },
        data() {
            return {
                editorsPick: [],
                sportsTagNews: [],
                pageNo: 1,

            }
        },
        components: {
            EditorPicks, SideBar
        },
        mounted() {
            this.getTagDetailNews();
        },
        serverPrefetch() {
            return this.getTagDetailNews();
        },
        watch: {
            '$route.params.page'() {
                this.getTagDetailNews();
            }
        },
        methods: {
            getTagDetailNews() {
                this.is_data_ready = false;
                let params = {
                    sport: this.$route.params.sport,
                    slug: this.$route.params.slug,
                    page: this.$route.params.page
                }
                this.pageNo = this.$route.params.page;
                return this.$axios.get(this.$GetSportsTagNews, {params})
                    .then(response => {
                        this.is_data_ready = true;
                        this.sportsTagNews = response.data.tagArticles;
                        this.editorsPick = response.data.editorsPick;
                    })
                    .catch(error => {
                    })
            },
        }
    }
</script>

<style lang="scss">
@import '~@/assets/scss/tier_sports.scss';
@import '~@/assets/scss/right_sidebar.scss';
</style>